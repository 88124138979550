import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'

const EmailVerified = () => {
	const { t } = useTranslation()

	return (
		<Box>
				<Typography variant="body1">{t('approval.email_verified.text')}</Typography>
		</Box>
	)
}

export default EmailVerified
