import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'
import qs from 'qs'

const Result = (props) => {
	const { t } = useTranslation()
	const params = qs.parse(props.location.search, { ignoreQueryPrefix: true })
	const result = params.result

	return (
		<Box>
			{result && <Typography variant="body1">{t(`identification.result.text.${result}`)}</Typography>}
			<Typography variant="body1">{t(`identification.result.text.close`)}</Typography>
		</Box>
	)
}

export default Result
