import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from '../../utils/axios'
import camelcaseKeys from 'camelcase-keys'
import { Box, Typography, Checkbox, Button, FormControlLabel } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import PromptFailure from '../Failure/Failure'
import { SnackbarContext } from '../../context/SnackbarContext'

const Identification = () => {
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const { id } = useParams()
	const [promptLoading, setPromptLoading] = useState(false)
	const [promptValid, setPromptValid] = useState(false)
	const [error, setError] = useState(null)

	const [accepted, setAccepted] = useState(false)

	useEffect(() => {
		const loadPrompt = async () => {
			setPromptLoading(true)
			setPromptValid(false)
			setError(null)

			axios
				.get(`/prompt/${id}/status`)
				.then(({ data }) => {
					const prompt = camelcaseKeys(data, { deep: true })

					setPromptValid(prompt.status === 'success' && prompt.result === 'pending')

					if (prompt.result !== 'pending') {
						switch (prompt.result) {
							case 'approved':
								setError('identification.failure.text.processed')
								break
							case 'declined':
								setError('identification.failure.text.declined')
								break
							default:
								setError(prompt.reason || t('identification.failure.text.default'))
						}
					}
					setPromptLoading(false)
				})
				.catch((e) => {
					switch (e.response.status) {
						case 404:
							setError('identification.failure.text.not_found')
							break
						default:
							const data = e.toJSON()
							setError(data.message || 'identification.failure.text.default')
					}
					setPromptLoading(false)
				})
		}
		loadPrompt()
	}, [id, t])

	const handleAcceptedChange = (event) => {
		setAccepted(event.target.checked)
	}

	const handleContinueToIdentification = () => {
		axios
			.get(`/prompt/${id}/e-ident/start`)
			.then(({ data }) => {
				window.location.href = data.result.returnLinkUrl
			})
			.catch((e) => {
				const data = e.toJSON()
				showMessage(data.message, 'error')
			})
	}

	return promptLoading ? (
		<LinearProgress />
	) : promptValid ? (
		<Box>
			<Box>
				<Typography variant="body1">{t('identification.text1')}</Typography>
			</Box>
			<Box mt={3}>
				<Typography variant="body1">{t('identification.text2')}</Typography>
			</Box>
			<Box mt={3}>
				<Typography variant="body1">{t('identification.text3')}</Typography>
			</Box>
			<Box mt={3}>
				<FormControlLabel
					control={<Checkbox checked={accepted} onChange={handleAcceptedChange} color="primary" />}
					label={t('identification.checkbox_text_1')}
				/>
			</Box>
			<Box mt={2}>
				<Button
					variant="contained"
					color="primary"
					disabled={!accepted}
					fullWidth
					onClick={handleContinueToIdentification}
				>
					{t('identification.button_text')}
				</Button>
			</Box>
		</Box>
	) : (
		<PromptFailure message={error} />
	)
}

export default Identification
