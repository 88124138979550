import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  verifyButtonWrapper: {
    textAlign: 'center'
  },
  verifyButton: {
  },
}))

export default useStyles
