import { useContext, useEffect } from 'react'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'

import { SnackbarProvider, LanguageProvider } from './context'
import Snackbar from './components/shared/Snackbar'

import { LanguageContext } from './context'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createLocalizedTheme } from './utils/theme'
import AppRouter from './router'

import * as themes from 'theme'
import useQueryParams from 'utils/queryParams'

const AppTheme = () => {
	let { language, setLanguage } = useContext(LanguageContext)
	const query = useQueryParams(useLocation().search)

	useEffect(() => {
		setLanguage(query.lang)
	}, [query.lang])

	const theme = createLocalizedTheme(themes[query.merchant], language)
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<SnackbarProvider>
				<AppRouter />
			</SnackbarProvider>
		</ThemeProvider>
	)
}

const App = () => {
	return (
		<div className="App">
			<Router>
				<LanguageProvider>
					<AppTheme />
				</LanguageProvider>
			</Router>
			<Snackbar />
		</div>
	)
}

export default App
