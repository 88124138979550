import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#F15C25',
		},
		common: {
			black: '#1E2224',
		},
	},
	typography: {
		fontFamily: [
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		lineHeight: '28',
		fontSize: 16,
		body1: {
			fontWeight: '300',
			letterSpacing: '-0.4px',
		},
		button: {
			fontFamily: "Arial, sans-serif",
			fontSize: '1rem',
			textTransform: 'none',
			borderRadius: '8px',
		},
	},
	shape: {
		borderRadius: 8
	},
	overrides: {
		MuiButton: {
			root: {
				padding: '13px 17px',
			},
		},
		MuiCssBaseline: {
			'@global': {
				body: {
					backgroundColor: '#f4f4f4',
					color: '#000000'
				},
			},
		},
	},
})

export default theme
