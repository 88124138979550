import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Box, Container } from '@material-ui/core'

import { LanguageContext } from '../../context'
import { createLocalizedTheme } from '../../utils/theme'

import useStyles from './Main.styles'
import logoWalley from 'assets/images/logo-walley.svg'
import logoPower from 'assets/images/logo-power.png'
import useQueryParams from 'utils/queryParams'

const Main = (props) => {
	const { children } = props

	const classes = useStyles()
	const { language } = useContext(LanguageContext)

	const logos = {
		walley: logoWalley,
		power: logoPower,
	}

	const query = useQueryParams(useLocation().search)
	const logo = logos[query.merchant]

	return (
		<ThemeProvider theme={(outerTheme) => createLocalizedTheme(outerTheme, language)}>
			<CssBaseline />
			<Container maxWidth="sm">
				<Paper elevation={2} className={classes.root}>
					<Box bgcolor="common.black" className={classes.header}>
						<img alt="logo" src={logo} className={classes.logo} />
					</Box>
					<Box mt={2} bgcolor="common.white" className={ classes.content}>{children}</Box>
				</Paper>
			</Container>
		</ThemeProvider>
	)
}

export default Main
