const API_URL = process.env.REACT_APP_API_URL

const LANGUAGES = {
  en: 'English',
  fi: 'Finnish',
  no: 'Norwegian'
}
const LOCALES = {
  en: 'enUS',
  fi: 'fiFI'
}

const DEFAULT_LANGUAGE = Object.keys(LANGUAGES)[2]

const THEMES = {
  'walley': 'Walley',
  'power': 'Power'
}
const DEFAULT_THEME = Object.keys(THEMES)[0]

export { API_URL, LANGUAGES, DEFAULT_LANGUAGE, LOCALES, THEMES, DEFAULT_THEME }
