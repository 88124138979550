import axios from 'axios'
import { API_URL } from '../config/constants'

const headers = {}

const instance = axios.create({
	baseURL: API_URL,
	headers,
})

export default instance
