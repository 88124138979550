import { makeStyles } from '@material-ui/core/styles'
import { auto } from 'async'

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: '20vh',
		minHeight: '660px',
		overflow: 'hidden',
		boxShadow: 'none',
		borderRadius: 0,
		background: 'transparent',
	},
	header: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	logo: {
		width: '100%',
		height: 'auto',
	},
	content: {
		margin: auto,
		padding: '32px',
	},
}))

export default useStyles
