import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'

const Failure = (props) => {
	const { t } = useTranslation()

	return (
		<Box>
			<Typography variant="body1">{t(props.message)}</Typography>
		</Box>
	)
}

export default Failure
