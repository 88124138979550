import React from 'react'
import { Switch } from 'react-router-dom'
import Main from './layouts/Main'
import RouteWithLayout from './components/shared/RouteWithLayout'

import { Result, Approval, Identification, EmailVerified } from './pages'

const AppRouter = () => (
	<Switch>
		<RouteWithLayout layout={Main} exact path="/" component={Result} />
		<RouteWithLayout layout={Main} exact path="/email-verified" component={EmailVerified} />
		<RouteWithLayout layout={Main} exact path="/:id" component={Approval} />
		<RouteWithLayout layout={Main} exact path="/:id/eident" component={Identification} />
	</Switch>
)

export default AppRouter
