import { DEFAULT_THEME } from 'config/constants'
import { LANGUAGES } from 'config/constants'
import { DEFAULT_LANGUAGE } from 'config/constants'
import { THEMES } from 'config/constants'
import qs from 'qs'

const useQueryParams = (search) => {
	const query = qs.parse(search, { ignoreQueryPrefix: true })

	let merchant = query.merchant
	merchant = merchant && Object.keys(THEMES).includes(merchant) ? merchant : DEFAULT_THEME

	let lang = query.lang
	lang = lang && Object.keys(LANGUAGES).includes(lang) ? lang : DEFAULT_LANGUAGE

	return {
		lang,
		merchant,
	}
}

export default useQueryParams
