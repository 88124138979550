import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from '../../utils/axios'
import camelcaseKeys from 'camelcase-keys'
import { Box, Typography, Button } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import PromptFailure from '../Failure/Failure'
import { SnackbarContext } from '../../context/SnackbarContext'
import useStyles from './Approval.styles'
import { useHistory, useLocation } from 'react-router-dom'
import useQueryParams from '../../utils/queryParams'
import EmailVerified from '../EmailVerified'

const Approval = () => {
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const { id } = useParams()
	const [promptLoading, setPromptLoading] = useState(false)
	const [promptValid, setPromptValid] = useState(false)
	const [error, setError] = useState(null)
	const [verifying, setVerifying] = useState(false)

	const history = useHistory()
	const query = useQueryParams(useLocation().search)

	useEffect(() => {
		const loadPrompt = async () => {
			setPromptLoading(true)
			setPromptValid(false)
			setError(null)

			axios
				.get(`/prompt/${id}/status`)
				.then(({ data }) => {
					const prompt = camelcaseKeys(data, { deep: true })

					setPromptValid(prompt.status === 'success' && prompt.result === 'pending')

					if (prompt.result !== 'pending') {
						switch (prompt.result) {
							case 'approved':
								setError('approval.failure.text.processed')
								break
							case 'declined':
								setError('approval.failure.text.declined')
								break
							default:
								setError(prompt.reason || 'approval.failure.text.default')
						}
					} else {
						handleVerify()
					}
					setPromptLoading(false)
				})
				.catch((e) => {
					switch (e.response.status) {
						case 404:
							setError('approval.failure.text.not_found')
							break
						default:
							const data = e.toJSON()
							setError(data.message || 'approval.failure.text.default')
					}
					setPromptLoading(false)
				})
		}
		loadPrompt()
	}, [id])

	const handleVerify = () => {
		setVerifying(true)
		axios
			.get(`/prompt/${id}/approve`)
			.then(({ data }) => {
				if (data.status === 'success') {
					history.push(`/email-verified?merchant=${query.merchant}&lang=${query.lang}`)
				} else {
					showMessage(data.reason, 'error')
				}
			})
			.catch((e) => {
				const data = e.toJSON()
				showMessage(data.message, 'error')
			})
	}

	const classes = useStyles()

	return promptLoading ? (
		<LinearProgress />
	) : promptValid ?
		<EmailVerified />
		: (
		<PromptFailure message={error} />
	)
}

export default Approval
